import classes from "./General.module.css";

const Creativity = () => {
  return (
    <div className={classes.card}>
      <h1>& Creativity</h1>
      <p>
        Looking ahead keeps us sharp and strategic. We embrace bold ideas and
        new ways of thinking, no matter waht industry we are working with.
      </p>
    </div>
  );
};

export default Creativity;
