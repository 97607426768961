import { useRef } from "react";

import classes from "./Header.module.css";
// import logo from "../../Images/logo.png";
import Nav from "./Nav";
import MenuIcon from "../UI/MenuIcon";
import { chambers } from "../../config/config";

const Header = () => {
  const menuNav = useRef();

  const toggleMenuHandler = () => {
    menuNav.current.classList.toggle(classes.hidden);
  };

  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <h1>{chambers}</h1>
      </div>
      <div ref={menuNav} className={classes.hidden}>
        <Nav />
      </div>
      <div className={classes.menu} onClick={toggleMenuHandler}>
        <h2>MENU</h2>
        <MenuIcon />
      </div>
    </header>
  );
};

export default Header;
