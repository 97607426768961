import classes from "./AboutUs.module.css";
import { chambers, country } from "../../config/config";

const AboutUs = () => {
  return (
    <section className={classes.about}>
      <div className={classes.detail}>
        <h1>Diversity, equity and inclusion</h1>
        <p>
          DEI is an ongoing journey, but we're committed to making sure everyone
          at {chambers} feels respected, is comfortable being their authentic
          selves, and has equitable opportunities to reach their goals.{" "}
          {chambers} has always been known as unconventional. We think embracing
          diversity of thought makes us a stronger partner to our clients. Our
          Diversity, Equity and Inclusion Committee is made up of a diverse
          group of lawyers and staff. We nurture inclusion across the firm and
          roll out diversity-related initiatives.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Employer and community partnerships</h1>
        <p>
          We belong to the Law Firm Diversity and Inclusion Network, a group of
          {country} law firms that promote diversity and inclusion in their
          firms and the broader legal profession. Our voluntary program focuses
          on retention in private practice through resources, programs, and
          policies that support female lawyers. We're also an employer partner
          with the {country} Centre for Diversity and Inclusion, which provides
          us with valuable DEI resources and training sessions. As a community
          partner to {country} Pride since 2019, we participate in the annual
          parade and show our support. We've also hosted an LGBTQ2S+ workshop on
          engaging in effective allyship.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
