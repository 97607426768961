import classes from "./Body.module.css";
import Skills from "../cards/Skills";
import Drive from "../cards/Drive";
import Creativity from "../cards/Creativity";
import Ambition from "../cards/Ambition";
import Support from "../cards/Support";

const Body = () => {
  return (
    <section className={classes.body}>
      <Skills />
      <Drive />
      <Creativity />
      <Ambition />
      <Support />
    </section>
  );
};

export default Body;
