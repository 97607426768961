import classes from "./PracticeAreas.module.css";

const PracticeAreas = () => {
  return (
    <section className={classes.practice}>
      <div className={classes.areas}>
        <h1>Litigation and dispute resolution</h1>
        <p>
          Our skilled negotiators find the right approach to resolve your issues
          as efficiently and quickly as possible.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Mergers, acquisitions and other strategic transactions</h1>
        <p>
          We have a reputation as leaders in the Canadian market, handling
          sophisticated, high-profile transactions.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Regulatory, environmental and Indigenous</h1>
        <p>
          Sound, effective service from a team skilled in the intricacies of
          administrative law across many industries.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Restructuring and insolvency</h1>
        <p>
          Efficient guidance for companies and financial institutions dealing
          with business and financial distress.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Tax</h1>
        <p>
          Comprehensive, insightful advice from a team experienced in developing
          domestic and cross-border strategies.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Capital markets</h1>
        <p>
          We're recognized for our experience with private placements and public
          offerings for all types of issuers.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Commercial real estate</h1>
        <p>
          Guidance for commercial matters, including ownership, development,
          construction, financing, and leasing.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Competition and foreign investment</h1>
        <p>
          Major national and international transactions, civil actions, and
          proceedings, plus common compliance issues.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Employment and labour</h1>
        <p>
          Navigate complex employment and labour matters with a well-connected
          cross-disciplinary team.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Energy</h1>
        <p>
          As recognized leaders in the field, we advise large national and
          international corporations, as well as startups.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Environmental, social, and governance (ESG)</h1>
        <p>
          A proactive approach to help you seize opportunities, reduce risk, and
          create sound policies and practices.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Infrastructure and project development</h1>
        <p>
          Creative solutions for diverse clients on all aspects of domestic and
          international projects and transactions.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Intellectual property</h1>
        <p>
          Navigate the legal processes and procedures needed to protect your
          ideas, products, or technology.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Wills and estates</h1>
        <p>
          Guidance and planning to provide peace of mind and reassurance that
          your intentions are honoured.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Banking and finance</h1>
        <p>
          Complex transactions, financing strategies, business opportunities,
          and the development of major projects.
        </p>
      </div>
      <div className={classes.areas}>
        <h1>Business law</h1>
        <p>
          Whatever your industry, get tailored, savvy solutions that mitigate
          risk and provide the results you need.
        </p>
      </div>
    </section>
  );
};

export default PracticeAreas;
