import classes from "./Team.module.css";
import denzel from "../../Images/lawyer1.jpg";
import sean from "../../Images/lawyer2.jpg";
import max from "../../Images/lawyer3.jpg";
import mark from "../../Images/lawyer4.jpg";

const Team = () => {
  return (
    <section className={classes.team}>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={denzel} alt="Denzel Wilberforce" />
        </div>
        <div className={classes.contact}>
          <h2>Denzel Farrugia</h2>
          <p>Director</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={sean} alt="Sean Creed" />
        </div>
        <div className={classes.contact}>
          <h2>Sean Appleton</h2>
          <p>Partner</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={max} alt="Max mallin" />
        </div>
        <div className={classes.contact}>
          <h2>Max Archinal</h2>
          <p>Associate</p>
        </div>
      </div>
      <div className={classes.attorney}>
        <div className={classes.photo}>
          <img src={mark} alt="Max mallin" />
        </div>
        <div className={classes.contact}>
          <h2>Mark Barnetta</h2>
          <p>Associate</p>
        </div>
      </div>
    </section>
  );
};

export default Team;
