import classes from "./General.module.css";

const Drive = () => {
  return (
    <div className={classes.card}>
      <h1>& Drive</h1>
      <p>
        We norture a culture that includes time for fun and philantropy, because
        it keeps us engaged, energized and inspired.
      </p>
    </div>
  );
};

export default Drive;
