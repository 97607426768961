import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/main/Footer";
import Whatsapp from "../components/UI/Whatsapp";

import Header from "../components/main/Header";

const RootLayout = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <Outlet />
      </main>
      <Whatsapp />
      <Footer />
    </Fragment>
  );
};

export default RootLayout;
