import { Link } from "react-router-dom";

import classes from "./Nav.module.css";

const Nav = () => {
  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/team">Team</Link>
        </li>
        <li>
          <Link to="/practiceAreas">Practice Areas</Link>
        </li>
        <li>
          <Link to="/industries">Industries</Link>
        </li>
        <li>
          <Link to="/contactUs">Contact Us</Link>
        </li>
        <li>
          <Link to="/aboutUs">About Us</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
