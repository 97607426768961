import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import { chambers, email, address } from "../../config/config";

const Footer = () => {
  return (
    <section className={classes.footer}>
      <nav className={classes.nav}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/practiceAreas">Practice Areas</Link>
          </li>
          <li>
            <Link to="/industries">Industries</Link>
          </li>
          <li>
            <Link to="/contactUs">Contact Us</Link>
          </li>
          <li>
            <Link to="/aboutUs">About Us</Link>
          </li>
        </ul>
      </nav>
      <div className={classes.address}>
        <div className={classes.office}>
          <h2>{chambers}</h2>

          <address>{address}</address>
        </div>
        <div className={classes.contact}>
          <p>{email}</p>
        </div>
      </div>
      <p>
        {chambers} | @ Copyright {chambers} 2024. All rights reserved
      </p>
    </section>
  );
};

export default Footer;
